import { Form, useNavigate, useSearchParams } from 'react-router';

import { useRootLoaderData } from '~/utils/useRootLoaderData';

export default function PropertySearchForm() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    userProfile: { stateOptionMap, productOptionMap },
  } = useRootLoaderData() as {
    userProfile: {
      stateOptionMap: Record<string, string[]>;
      productOptionMap: Record<string, string[]>;
    };
  };

  let defaultState = '';
  let defaultProduct = '';
  if (Object.entries(stateOptionMap).length === 1) {
    const [onlyState] = Object.keys(stateOptionMap);
    defaultState = onlyState;

    if (stateOptionMap[defaultState]?.length === 1)
      defaultProduct = stateOptionMap[onlyState][0];
  }

  return (
    <Form
      method="GET"
      className="mb-4 flex flex-wrap items-end gap-3"
      action="/search"
      id="propertySearchForm"
    >
      <div className="form-group flex grow flex-col md:grow-0">
        <label htmlFor="state" className="mb-0.5 text-sm font-bold">
          Select State
        </label>
        <select
          name="state"
          id="state"
          defaultValue={searchParams.get('state') || defaultState}
          required
        >
          <option className="placeholder" value="" disabled>
            Please Select...
          </option>
          {Object.keys(stateOptionMap).map(state => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group flex grow flex-col md:grow-0">
        <label htmlFor="product" className="mb-0.5 text-sm font-bold">
          Select Product
        </label>
        <select
          name="product"
          id="product"
          defaultValue={searchParams.get('product') || defaultProduct}
          required
        >
          <option className="placeholder" value="" disabled>
            Please Select...
          </option>
          {Object.keys(productOptionMap).map(product => (
            <option key={product} value={product}>
              {product}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group flex grow flex-col xl:grow-0">
        <label htmlFor="firstName" className="mb-0.5 text-sm font-bold">
          First Name
        </label>
        <input
          defaultValue={searchParams.get('firstName') || ''}
          name="firstName"
          id="firstName"
          placeholder="First Name Search"
          required
        />
      </div>
      <div className="form-group flex grow flex-col xl:grow-0">
        <label htmlFor="lastName" className="mb-0.5 text-sm font-bold">
          Last Name
        </label>
        <input
          name="lastName"
          id="lastName"
          defaultValue={searchParams.get('lastName') || ''}
          placeholder="Last Name Search"
          required
        />
      </div>
      <div className="form-group flex grow flex-col lg:grow-0">
        <label htmlFor="effectiveDate" className="mb-0.5 text-sm font-bold">
          Effective Date
        </label>
        <input
          type="date"
          name="effectiveDate"
          defaultValue={searchParams.get('effectiveDate') || ''}
          id="effectiveDate"
          placeholder="MM/DD/YYYY"
          required
        />
      </div>
      <div className="form-group flex min-w-full grow flex-col md:min-w-min">
        <label htmlFor="propertyAddress" className="mb-0.5 text-sm font-bold">
          Property Street Address
        </label>
        <input
          name="propertyAddress"
          id="propertyAddress"
          defaultValue={searchParams.get('propertyAddress') || ''}
          placeholder="Property Street Address Search"
          required
        />
      </div>
      <div className="button-wrapper flex grow justify-end md:grow-0 lg:grow xl:grow-0">
        <button
          className="btn btn-neutral btn-sm mr-2 text-white drop-shadow"
          form="propertySearchForm"
          type="reset"
          onClick={() => navigate('/', { replace: true })}
        >
          Reset
        </button>
        <button
          className="btn btn-primary btn-sm text-white drop-shadow"
          type="submit"
          data-test="submit"
          form="propertySearchForm"
        >
          <svg className="h-3.5 w-3.5" viewBox="0 0 512 512">
            <path
              d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z"
              fill="currentColor"
            ></path>
          </svg>
          Search
        </button>
      </div>
    </Form>
  );
}
